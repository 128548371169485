@font-face {
  font-family: 'OMEGA CT';
  src: url('OmegaCT-Light.woff2') format('woff2'), url('OmegaCT-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'OMEGA CT';
  src: url('OMEGACT-Regular.woff2') format('woff2'), url('OMEGACT-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OMEGA CT';
  src: url('OMEGACT-Italic.woff2') format('woff2'), url('OMEGACT-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'OMEGA CT';
  src: url('OMEGACT-Bold.woff2') format('woff2'), url('OMEGACT-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
