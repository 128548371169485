@font-face {
  font-family: OMEGA CT;
  src: url("OmegaCT-Light.41edbd3a.woff2") format("woff2"), url("OmegaCT-Light.7daa75a9.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: OMEGA CT;
  src: url("OMEGACT-Regular.34a23eb9.woff2") format("woff2"), url("OMEGACT-Regular.4198f429.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: OMEGA CT;
  src: url("OMEGACT-Italic.01109a10.woff2") format("woff2"), url("OMEGACT-Italic.aca3b297.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: OMEGA CT;
  src: url("OMEGACT-Bold.7f8e1735.woff2") format("woff2"), url("OMEGACT-Bold.92d2103a.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
/*# sourceMappingURL=index.91c6cb94.css.map */
